import { Content } from '@/Models'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Matrix extends Vue {
  @Prop({ required: true })
  index?: number
  @Prop({ required: true })
  content?: Content

  remove() {
    this.$emit('deleteInput', { index: this.index })
  }
}
// @deleteInput="deleteQuadrant"
//       @changeTitleInput="changeTitleInput"
//       @changeCodeInput="changeCodeInput"
